import React, { Component } from "react";
// import {URL_IMG} from "../../constants";

class Footer extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
		}
	}
	componentDidMount(){
		window.scrollTo(0, 0);
	}
	render(){
		return(
			<div>
			<footer class="footer">
					<div class="container footer-inner">
							<div class="row">
									<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
											<div class="footer-item">
													<h4>Contact Us</h4>
													<ul class="contact-info">
															<li>
																	Address: Yasmeen Street,
															</li>
															<li>
																	Opposite Rahmaniya
															</li>
															<li>
																	Ajman, United Arab Emirates
															</li>
														</ul>
											</div>
									</div>
									<div class="col-xl-2 col-lg-2 col-md-6 col-sm-12">
											{/* <div class="footer-item">
													<h4>Useful Links</h4>
													<ul class="links">
															<li>
																	<a href="/about.html">About us</a>
															</li>
															<li>
																	<a href="/services.html">Service</a>
															</li>
															<li>
																	<a href="/portfolio-3column.html">Portfolio</a>
															</li>
															<li>
																	<a href="contact.html">Contact</a>
															</li>
															<li>
																	<a href="/blog-classic-sidebar-right.html">Blog</a>
															</li>
															<li>
																	<a href="/gallery-3column.html">Gallery</a>
															</li>
															<li>
																	<a href="/team.html">Team</a>
															</li>
															<li>
																	<a href="/pricing-tables-1.html">Pricing Tables</a>
															</li>
													</ul>
											</div> */}
									</div>
									<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
											{/* <div class="recent-posts footer-item">
													<h4>Recent Posts</h4>
													<div class="media mb-4">
															<a class="pr-3" href="/portfolio-details.html">
																	<img src={URL_IMG + "75x75.png"} alt="recent-portfolio"/>
															</a>
															<div class="media-body align-self-center">
																	<p><a href="/portfolio-details.html">Lectus tristique lacinia non in diam mauris ultricies rutrum mauris.</a></p>
																	<p><i class="fa fa-calendar"></i>27 Feb 2018</p>
															</div>
													</div>
													<div class="media mb-4">
															<a class="pr-3" href="/portfolio-details.html">
																	<img src={URL_IMG + "75x75.png"} alt="recent-portfolio"/>
															</a>
															<div class="media-body align-self-center">
																	<p><a href="/portfolio-details.html">Lectus tristique lacinia non in diam mauris ultricies rutrum mauris</a></p>
																	<p><i class="fa fa-calendar"></i>27 Feb 2018</p>
															</div>
													</div>
													<div class="media">
															<a class="pr-3" href="/portfolio-details.html">
																	<img src={URL_IMG + "75x75.png"} alt="recent-portfolio"/>
															</a>
															<div class="media-body align-self-center">
																	<p><a href="/portfolio-details.html">Lectus tristique lacinia non in diam mauris ultricies rutrum mauris</a></p>
																	<p><i class="fa fa-calendar"></i>27 Feb 2018</p>
															</div>
													</div>
											</div> */}
									</div>
									<div class="col-xl-3 col-lg-3 col-md-6 col-sm-12">
											<div class="footer-item clearfix">
													<h4>Contact Us</h4>
													<ul>
														
													<li>
																	Email: <a href="mailto:carryfreshmarket@gmail.com">carryfreshmarket@gmail.com</a>
															</li>
															<li>
																	Phone: <a href="tel:Carryfresh Market">050 6209570, 06 522 4825</a>
															</li>
													</ul>
													<ul class="social-list clearfix">
															<li><a href="#" class="facebook-bg"><i class="fa fa-facebook"></i></a></li>
															<li><a href="#" class="twitter-bg"><i class="fa fa-twitter"></i></a></li>
															<li><a href="#" class="google-bg"><i class="fa fa-google-plus"></i></a></li>
															<li><a href="#" class="rss-bg"><i class="fa fa-rss"></i></a></li>
													</ul>
													{/* <h4>Subscribe</h4>
													<div class="Subscribe-box">
															<p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.</p>
															<form action="#" method="GET">
																	<p>
																			<input type="text" class="form-contact" name="email" placeholder="Enter Address"/>
																	</p>
																	<p>
																			<button type="submit" name="submitNewsletter" class="btn btn-block btn-color">
																					Subscribe
																			</button>
																	</p>
															</form>
													</div> */}
											</div>
									</div>
							</div>
					</div>
			</footer>
				<div class="sub-footer">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<p class="copy">&copy;  2024 Carryfresh Market</p>
							</div>
						</div>
					</div>
				</div>

				<div id="full-page-search">
					<button type="button" class="close">×</button>
					<form action="#">
						<input type="search" value="" placeholder="type keyword(s) here" />
						<button type="button" class="btn btn-sm btn-color">Search</button>
					</form>
				</div>

				<div class="off-canvas-sidebar">
						<div class="off-canvas-sidebar-wrapper">
								<div class="off-canvas-header">
										<a class="close-offcanvas" href="#"><span class="fa fa-times"></span></a>
								</div>
								<div class="off-canvas-content">
										<aside class="canvas-widget">
												<div class="logo text-center">
														<img src="assets/img/logos/logo-white.png" alt="logo"/>
												</div>
										</aside>
										<aside class="canvas-widget">
												<ul class="menu">
														<li class="menu-item menu-item-has-children"><a href="/index.html">Home</a></li>
														<li class="menu-item"><a href="/about.html">About Us</a></li>
														<li class="menu-item"><a href="/portfolio-3column.html">Portfolio</a></li>
														<li class="menu-item"><a href="/services.html">Services</a></li>
														<li class="menu-item"><a href="/team.html">Team</a></li>
														<li class="menu-item"><a href="contact.html">Contact US</a></li>
												</ul>
										</aside>
										<aside class="canvas-widget">
												<ul class="social-icons">
														<li><a href="#"><i class="fa fa-facebook"></i></a></li>
														<li><a href="#"><i class="fa fa-twitter"></i></a></li>
														<li><a href="#"><i class="fa fa-google-plus"></i></a></li>
														<li><a href="#"><i class="fa fa-linkedin"></i></a></li>
														<li><a href="#"><i class="fa fa-vk"></i></a></li>
												</ul>
										</aside>
								</div>
						</div>
				</div>
				</div>)
	}
} 
export default Footer