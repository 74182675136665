import React, { Component } from "react";
import { withRouter } from 'react-router';
import Header from '../Header'
import Footer from "../Footer";
import axios from 'axios';
    
class About extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
			stores:[]
		}
	}
  componentDidMount(){
		// axios
		// .get("https://api.qamaralmadinauae.com/api/get-site-data")
		// .then((response) => {
		// 	this.setState({stores:response.data.stores})
		// })
  }
	render(){
		const {stores} = this.state;
		return(
			<div id="home-component">
				<Header/>
				<div class="blog-section content-area-2">
					<div class="container">
					{stores.map((store,index)=>{
						if(index%3==0)
							return(
								<div class="row">
									{stores.slice(index,index+3).map(item=>{
										return(
											<div class="col-lg-4 col-md-6 col-sm-12">
												<div class="blog-1">
													<div class="blog-photo">
														<img src={item.image} alt="avatar" class="img-fluid"/>
													</div>
													<div class="detail">
														<h3>
															<a href="#">{item.title}</a>
														</h3>
														<p style={{whiteSpace: 'break-spaces'}}>{item.description}</p>
													</div>
												</div>
											</div>
										) 
									})}
								</div>
							)
						})}
					</div>
				</div>
			<Footer/>
		</div>)
	}
}
export default withRouter(About)
