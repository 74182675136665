import React, { Component } from "react";

class Contact extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
		}
	} 
	componentDidMount(){
		window.scrollTo(0, 0);
	}
	render(){
		return(
			<div class="intro-section">
					<div class="container">
							<div class="row">
									<div class="col-lg-8 col-md-8">
											<div class="intro-text">
													<h3>have any queries? call us anytime</h3>
											</div>
									</div>
									<div class="col-lg-4 col-md-4">
											<a href="contact.html" class="btn btn-md">Contact Us</a>
									</div>
							</div>
					</div>
			</div>)
	}
} 
export default Contact