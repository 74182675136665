import React, { Component } from "react";
import {URL_IMG} from "../../constants";

class Projects extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
		}
	}
	render(){
		const {homeProjects} = this.props;
		return(
			<div class="portfolio-area content-area-2 projects-area" id="projects">
				<div class="container">
					<div class="main-title">
						<h1><span>Our</span> Projects</h1>
					</div>
					<div class="row">
						<div class="col-lg-12">
						  <div id="myCarousel2" class="carousel Project-details-sliders slide mb-50" data-ride="carousel">
								<div class="carousel-inner">
										{homeProjects.map(retail=>{
											return(
												<div class={"carousel-item"+(retail.seq_no==1?" active":"")}>
													<img class="img-fluid" src={URL_IMG + retail.image} alt="Second slide"/>
												</div>)
										})}
								</div>
								<div class="carousel-indicators">
								<button type="button" data-bs-target="#myCarousel2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
								<button type="button" data-bs-target="#myCarousel2" data-bs-slide-to="1" aria-label="Slide 2"></button>
								<button type="button" data-bs-target="#myCarousel2" data-bs-slide-to="2" aria-label="Slide 3"></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>)
	}
} 
export default Projects