import React, { Component } from "react";
import { withRouter } from 'react-router';
import Header from '../Header'
import Footer from "../Footer";
// import {URL_IMG} from "../../constants";
import axios from 'axios';
    
class About extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
			currentDiv:0,
			products:[],
			homeAbout:[{},{},{},{}],
			homeWholesales:[{},{},{},{}],
			homeRetails:[{},{},{},{}],
			homeOffers:[{},{},{}],
			homeProjects:[{},{},{}],
			homeBanners:[{},{},{}]
		}
	}
  componentDidMount(){
		// axios
		// .get("https://api.qamaralmadinauae.com/api/get-site-data")
		// .then((response) => {
		// 	this.setState({homeAbout:response.data.home_about})
		// })
  }
	render(){
		const {homeAbout} = this.state;
		return(
			<div id="home-component">
				<Header/>
				<div class="managment-area-2 content-area-7">
					<div class="container">
						<div class="row">
							<div class="col-lg-12 col-md-12">
								<div class="managment-info">
									<h1><span>About</span> Us</h1>
									<div class="managment-border-"></div>
									<p class="about-us-about">{"Since the establishment, we offer the best shopping experience for customers. Fueled with a corporate vision and dedicated professionals, we serve excellence in every single venture. With innovative approach and progressive marketing, we continue to grow our business."}</p>
								</div>
							</div>
												{/* <div class="agent content-area-2 bg-grea"> */}
							{/* <div class="container">
								<div class="main-title">
									<h1><span>Our</span> Team</h1>
									<p>Inspiring leaders behind our success.</p>
								</div>
								<div class="row">
									<div class="project-details-page content-area-16">
											<div class="container">
													<div class="row">
															<div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
																	<div class="agent-2">
																			<div class="agent-photo">
																					<a href="team.html">
																							<img src="assets/img/team/chairman.jpg" alt="avatar" class="img-fluid"/>
																					</a>
																			</div>
																			
																	</div>
															</div>
															<div class="col-lg-9 col-md-12 d-xl-block d-lg-block">
																	<div class="sidebar">
																			<div class="project-specification-2 widget wdt management-message">
																					<h3 class="title-2">Chairman’s Message</h3>
																					
																					<p>"Over the past decades, we have strengthened our organization by introducing several attractive policies and procedures. It’s my great pleasure to welcome you to the Carryfresh Market. With continuous development, we have transformed ourselves through strategic planning and organizational development. We have come a long way extending our vision. Still, we continue to nurture providing the best services!"</p>
																					<br/>
															<div class="management-chairman">
																					<h6>Valiyaparambath Aboobacker Abdullah Haji</h6>
																					<p>Chairman</p>
																					
																			</div>
																			</div>
																	</div>
															</div>
													</div>
											</div>
									</div>
									<div class="project-details-page content-area-16">
											<div class="container">
													<div class="row">
															<div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
																	<div class="agent-2">
																			<div class="agent-photo">
																					<a href="team.html">
																							<img src="assets/img/team/director-1.jpg" alt="avatar" class="img-fluid"/>
																					</a>
																			</div>
																			
																	</div>
															</div>
												<div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
																	<div class="agent-2">
																			<div class="agent-photo">
																					<a href="team.html">
																							<img src="assets/img/team/director-2.jpg" alt="avatar" class="img-fluid"/>
																					</a>
																			</div>
																			
																	</div>
															</div>
															<div class="col-lg-6 col-md-12 d-xl-block d-lg-block">
																	<div class="sidebar">
																			<div class="project-specification-2 widget wdt management-message">
																					<h3 class="title-2">Director’s message</h3>
																					
																					<p>"Carryfresh Market has come a long way in the business fraternity. With thoughtfully designed retail spaces, shopping is made easy and relaxing. We work with a motive to achieve the status ‘everything for you’. We offer a wide range of quality products at great value, Enjoy a complete shopping experience under one roof!"</p>
																					<br/>
															<div class="management-chairman">
																					<h6>Arshad & Mohammed Ali</h6>
																					<p>Directors</p>
																					
																			</div>
																			</div>
																	</div>
															</div>
												
													</div>
											</div>
									</div>
								</div>
							</div>
						</div> */}
			
						<div class="col-lg-5 col-md-5">
								<div class="managment-info">
									<h1><span>Why</span> Choose Us?</h1>
									<div class="managment-border-"></div>
									{/* <p>{homeAbout[0].description}</p> */}
									<p>{"We’re committed to deliver the best services focusing on consumers, what makes us different in staying rooted with ethical business practices,"}</p>
									<ul>
										<li style={{textAlign: 'left'}}><i class="flaticon-up-arrow"></i>Unmatched retail experience</li>
										<li style={{textAlign: 'left'}}><i class="flaticon-up-arrow"></i>Unique customer experience with exceptional quality and service</li>
										<li style={{textAlign: 'left'}}><i class="flaticon-up-arrow"></i>True to core values and deliver excellence</li>
										<li style={{textAlign: 'left'}}><i class="flaticon-up-arrow"></i>Continue to grow current sectors</li>
										<li style={{textAlign: 'left'}}><i class="flaticon-up-arrow"></i>Sustainable in the ever changing market</li>
									</ul>
									<p>We are unique by emphasizing on competitive prices and so, our customers relish with a convenient and quick shopping experience!</p>
									{/* <a href="/about" class="btn btn-lg btn-theme">Read More</a> */}
								</div>
							</div>
							<div class="col-lg-6 col-md-7 offset-lg-1">
								<div class="managment-slider">
									<div id="carouselExampleIndicators-managment" class="carousel slide" data-ride="carousel">
										<ol class="carousel-indicators">
											<li data-target="#carouselExampleIndicators-managment" data-slide-to="0" class="active"></li>
											<li data-target="#carouselExampleIndicators-managment" data-slide-to="1"></li>
											<li data-target="#carouselExampleIndicators-managment" data-slide-to="2"></li>
										</ol>
										<div class="carousel-inner">
											{homeAbout.map(about=>{
												if(about.seq_no>0)
													return(
														<div class={"carousel-item"+(about.seq_no==1?" active":"")}>
															<img class="d-block w-100 img-fluid" src={about.image} alt="Second slide"/>
														</div>)
											})}
										</div>
										<a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
											<span class="carousel-control-prev-icon" aria-hidden="true"></span>
											<span class="sr-only">Previous</span>
										</a>
										<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
											<span class="carousel-control-next-icon" aria-hidden="true"></span>
											<span class="sr-only">Next</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer/>
			</div>)
	}
}
export default withRouter(About)
