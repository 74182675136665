import React, { Component } from "react";
import { withRouter } from 'react-router';
import Header from '../Header'
import Footer from "../Footer";
    
class About extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1
		}
	}
	render(){
		return(
			<div id="home-component">
				<Header/>
<div class="project-details-page content-area-16">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div id="ProjectDetailsSlider" class="carousel Project-details-sliders slide mb-50">
                    <div class="carousel-inner">
                        {/* <div class="active item carousel-item" data-slide-number="0">
                            <img src={URL_IMG + "730x547.png"} class="img-fluid" alt="portfolio"/>
                        </div>
                        <div class="item carousel-item" data-slide-number="1">
                            <img src={URL_IMG + "730x547.png"} class="img-fluid" alt="portfolio"/>
                        </div>
                        <div class="item carousel-item" data-slide-number="2">
                            <img src={URL_IMG + "730x547.png"} class="img-fluid" alt="portfolio"/>
                        </div>
                        <div class="item carousel-item" data-slide-number="4">
                            <img src={URL_IMG + "730x547.png"} class="img-fluid" alt="portfolio"/>
                        </div>
                        <div class="item carousel-item" data-slide-number="5">
                            <img src={URL_IMG + "730x547.png"} class="img-fluid" alt="portfolio"/>
                        </div> */}
                        <a class="carousel-control left" href="#ProjectDetailsSlider" data-slide="prev"><i class="fa fa-angle-left"></i></a>
                        <a class="carousel-control right" href="#ProjectDetailsSlider" data-slide="next"><i class="fa fa-angle-right"></i></a>
                    </div>
                    <ul class="carousel-indicators smail-properties list-inline nav nav-justified">
                        {/* <li class="list-inline-item active">
                            <a id="carousel-selector-0" class="selected" data-slide-to="0" data-target="#ProjectDetailsSlider">
                                <img src={URL_IMG + "146x105.png"} class="img-fluid" alt="sub-portfolio"/>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a id="carousel-selector-1" data-slide-to="1" data-target="#ProjectDetailsSlider">
                                <img src={URL_IMG + "146x105.png"} class="img-fluid" alt="sub-portfolio"/>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a id="carousel-selector-2" data-slide-to="2" data-target="#ProjectDetailsSlider">
                                <img src={URL_IMG + "146x105.png"} class="img-fluid" alt="sub-portfolio"/>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a id="carousel-selector-3" data-slide-to="3" data-target="#ProjectDetailsSlider">
                                <img src={URL_IMG + "146x105.png"} class="img-fluid" alt="sub-portfolio"/>
                            </a>
                        </li> */}
                        {/* <li class="list-inline-item">
                            <a id="carousel-selector-4" data-slide-to="4" data-target="#ProjectDetailsSlider">
                                <img src={URL_IMG + "146x105.png"} class="img-fluid" alt="sub-portfolio"/>
                            </a>
                        </li> */}
                    </ul>
                </div>
                <div class="project-specification-2 widget wdt d-lg-none d-xl-none">
                    <h3 class="title-2">Project Info</h3>
                    <ul>
                        <li>
                            <strong>Category :</strong> Investment
                        </li>
                        <li>
                            <strong>Date :</strong> 14 February , 2018
                        </li>
                        <li>
                            <strong>Status :</strong> In Progress
                        </li>
                        <li>
                            <strong>Ranking :</strong> 5
                        </li>
                        <li>
                            <strong>Location:</strong> Dhaka Green Road
                        </li>
                        <li>
                            <strong>Website:</strong> <a href="mailto:carryfreshmarket@gmail.com">carryfreshmarket@gmail.com</a>
                        </li>
                    </ul>
                    <p>Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 d-none d-xl-block d-lg-block">
                <div class="sidebar">
                    <div class="project-specification-2 widget wdt">
                        <h3 class="title-2">Project Info</h3>
                        <ul>
                            <li>
                                <strong>Category :</strong> Investment
                            </li>
                            <li>
                                <strong>Date :</strong> 14 February , 2018
                            </li>
                            <li>
                                <strong>Status :</strong> In Progress
                            </li>
                            <li>
                                <strong>Ranking :</strong> 5
                            </li>
                            <li>
                                <strong>Location:</strong> Dhaka Green Road
                            </li>
                            <li>
                                <strong>Website:</strong> <a href="mailto:carryfreshmarket@gmail.com">carryfreshmarket@gmail.com</a>
                            </li>
                        </ul>
                        <p>Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
	
	<div class="project-details-page content-area-16">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div id="ProjectDetailsSlider-2" class="carousel Project-details-sliders slide mb-50">
                    <div class="carousel-inner">
                        {/* <div class="active item carousel-item" data-slide-number="0">
                            <img src={URL_IMG + "730x547.png"} class="img-fluid" alt="portfolio"/>
                        </div>
                        <div class="item carousel-item" data-slide-number="1">
                            <img src={URL_IMG + "730x547.png"} class="img-fluid" alt="portfolio"/>
                        </div>
                        <div class="item carousel-item" data-slide-number="2">
                            <img src={URL_IMG + "730x547.png"} class="img-fluid" alt="portfolio"/>
                        </div>
                        <div class="item carousel-item" data-slide-number="4">
                            <img src={URL_IMG + "730x547.png"} class="img-fluid" alt="portfolio"/>
                        </div>
                        <div class="item carousel-item" data-slide-number="5">
                            <img src={URL_IMG + "730x547.png"} class="img-fluid" alt="portfolio"/>
                        </div> */}
                        <a class="carousel-control left" href="#ProjectDetailsSlider" data-slide="prev"><i class="fa fa-angle-left"></i></a>
                        <a class="carousel-control right" href="#ProjectDetailsSlider" data-slide="next"><i class="fa fa-angle-right"></i></a>
                    </div>
                    <ul class="carousel-indicators smail-properties list-inline nav nav-justified">
                        {/* <li class="list-inline-item active">
                            <a id="carousel-selector-0" class="selected" data-slide-to="0" data-target="#ProjectDetailsSlider">
                                <img src={URL_IMG + "146x105.png"} class="img-fluid" alt="sub-portfolio"/>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a id="carousel-selector-1" data-slide-to="1" data-target="#ProjectDetailsSlider">
                                <img src={URL_IMG + "146x105.png"} class="img-fluid" alt="sub-portfolio"/>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a id="carousel-selector-2" data-slide-to="2" data-target="#ProjectDetailsSlider">
                                <img src={URL_IMG + "146x105.png"} class="img-fluid" alt="sub-portfolio"/>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a id="carousel-selector-3" data-slide-to="3" data-target="#ProjectDetailsSlider">
                                <img src={URL_IMG + "146x105.png"} class="img-fluid" alt="sub-portfolio"/>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a id="carousel-selector-4" data-slide-to="4" data-target="#ProjectDetailsSlider">
                                <img src={URL_IMG + "146x105.png"} class="img-fluid" alt="sub-portfolio"/>
                            </a>
                        </li> */}
                    </ul>
                </div>
                <div class="project-specification-2 widget wdt d-lg-none d-xl-none">
                    <h3 class="title-2">Project Info</h3>
                    <ul>
                        <li>
                            <strong>Category :</strong> Investment
                        </li>
                        <li>
                            <strong>Date :</strong> 14 February , 2018
                        </li>
                        <li>
                            <strong>Status :</strong> In Progress
                        </li>
                        <li>
                            <strong>Ranking :</strong> 5
                        </li>
                        <li>
                            <strong>Location:</strong> Dhaka Green Road
                        </li>
                        <li>
                            <strong>Website:</strong> <a href="mailto:carryfreshmarket@gmail.com">carryfreshmarket@gmail.com</a>
                        </li>
                    </ul>
                    <p>Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites.</p>
                </div>
                
            </div>
            <div class="col-lg-4 col-md-12 d-none d-xl-block d-lg-block">
                <div class="sidebar">
                    <div class="project-specification-2 widget wdt">
                        <h3 class="title-2">Project Info</h3>
                        <ul>
                            <li>
                                <strong>Category :</strong> Investment
                            </li>
                            <li>
                                <strong>Date :</strong> 14 February , 2018
                            </li>
                            <li>
                                <strong>Status :</strong> In Progress
                            </li>
                            <li>
                                <strong>Ranking :</strong> 5
                            </li>
                            <li>
                                <strong>Location:</strong> Dhaka Green Road
                            </li>
                            <li>
                                <strong>Website:</strong> <a href="mailto:carryfreshmarket@gmail.com">carryfreshmarket@gmail.com</a>
                            </li>
                        </ul>
                        <p>Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
			<Footer/>
		</div>)
	}
}
export default withRouter(About)
