import React, {Fragment,Component} from 'react';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Events from './pages/Events';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Media from './pages/Media';
import Stores from './pages/Stores';
import Offers from './pages/Offers';
import {BrowserRouter,Route } from "react-router-dom";

class App extends Component{
  constructor(props){
    super();
    this.state={
      dataFetched:false,
    }
  }
  render(){
    return (
      <div className="App">
        <BrowserRouter>
          <Route
            exact
            path="/"
            component={Home}
          />
          <Route
            exact
            path="/about"
            component={About}
          />
          <Route
            exact
            path="/events"
            component={Events}
          />
          <Route
            exact
            path="/stores"
            component={Stores}
          />
          <Route
            exact
            path="/projects"
            component={Projects}
          />
          <Route
            exact
            path="/contact"
            component={Contact}
          />
          <Route
            exact
            path="/media"
            component={Media}
          />
          <Route
            exact
            path="/our-offers"
            component={Offers}
          />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
