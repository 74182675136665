import React, { Component } from "react";
// import {URL_IMG} from "../../constants";

class Banner extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
		}
	}
	componentDidMount(){
		window.scrollTo(0, 0);
	}
	render(){
		const {homeBanners} = this.props;
		return(
			<div class="banner" id="banner"  onClick="position();">
				<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
					<div class="carousel-inner home-banner-carousel">
					{homeBanners.map((item, index)=>{
						return(
							<div class={"carousel-item item-bg"+(index==0?" active":"")}>
								<img class="d-block w-100 h-100" src={item.image} alt="banner"/>
								{/* <div class="carousel-caption banner-slider-inner d-flex h-100">
									<div class="carousel-content container">
										<div class="text-c banner-info-2">
											<h1 data-animation="animated fadeInDown delay-05s">{item.title}</h1>
											<p data-animation="animated fadeInUp delay-10s">{item.desciption}</p>
										</div>
									</div>
								</div> */}
							</div> 
						) 
					})}
					</div>
					<div class="carousel-inner home-banner-carousel-mob">
					{homeBanners.map((item, index)=>{
						return(
							<div class={"carousel-item item-bg"+(index==0?" active":"")} style={{height:"auto"}}>
								<img class="d-block w-100" src={item.image} alt="banner" ref={(el) => el && el.style.setProperty("width", "100%", "important")}/>
								{/* <div class="carousel-caption banner-slider-inner d-flex">
									<div class="carousel-content container">
										<div class="text-c banner-info-2">
											<h1 data-animation="animated fadeInDown delay-05s">{item.title}</h1>
											<p data-animation="animated fadeInUp delay-10s">{item.desciption}</p>
										</div>
									</div>
								</div> */}
							</div> 
						) 
					})}
					</div>
					<a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
						<span class="slider-mover-left" aria-hidden="true">
							<i class="fa fa-angle-left"></i>
						</span>
					</a>
					<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
						<span class="slider-mover-right" aria-hidden="true">
							<i class="fa fa-angle-right"></i>
						</span>
					</a>
				</div>
			</div>)
	}
} 
export default Banner