import React, { Component } from "react";

class Retail extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
		}
	}
	componentDidMount(){
		window.scrollTo(0, 0);
	}
	render(){
		const {homeRetails} = this.props;
		return(
			<div class="managment-area-2 content-area-2 about" id="wholesale">
			<div class="container">
				<div class="main-title">
					<h1><span>Carryfresh Market</span> Retail</h1>
					<p>{homeRetails[0].title}</p>
					{/* <p>Meet out small team that make those great products.</p> */}
				</div>
				<div class="row">
					<div class="col-lg-5 col-md-5">
						<div class="managment-info">
							
							<div class="managment-border-"></div>
							<p>{homeRetails[0].description}</p>
							{/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna<span> aliqua.Ut enim ad minim veniam, quis</span></p> */}
							{/* <ul>
								<li><i class="flaticon-up-arrow"></i>Deep Customization Process</li>
								<li><i class="flaticon-building"></i>Industry Specialization</li>
								<li><i class="flaticon-three"></i>Unique Sales Methodoligies</li>
							</ul> */}
							<a href="#" class="btn btn-lg btn-theme">Explore now</a>
						</div>
					</div>
					<div class="col-lg-6 col-md-7 offset-lg-1">
						<div class="managment-slider">
							<div id="carouselWholesale" class="carousel slide" data-ride="carousel">
								<ol class="carousel-indicators">
									<li data-target="#carouselWholesale" data-slide-to="0" class="active"></li>
									<li data-target="#carouselWholesale" data-slide-to="1"></li>
									<li data-target="#carouselWholesale" data-slide-to="2"></li>
								</ol>
								<div class="carousel-inner">
										{homeRetails.map(retail=>{
											if(retail.seq_no>0)
												return(
													<div class={"carousel-item"+(retail.seq_no==1?" active":"")}>
														<img class="d-block w-100 img-fluid" src={retail.image} alt="Second slide"/>
													</div>)
										})}
									{/* <div class="carousel-item active">
										<img class="d-block w-100 img-fluid" src={URL_IMG + "retail-banner-1.jpg"} alt="Second slide"/>
									</div>
									<div class="carousel-item">
										<img class="d-block w-100 img-fluid" src={URL_IMG + "retail-banner-2.jpg"} alt="First slide"/>
									</div>
									<div class="carousel-item">
										<img class="d-block w-100 img-fluid" src={URL_IMG + "almadina-2.jpg"} alt="Third slide"/>
									</div> */}
								</div>
								<a class="carousel-control-prev" href="#" role="button" data-slide="prev">
									<span class="carousel-control-prev-icon" aria-hidden="true"></span>
									<span class="sr-only">Previous</span>
								</a>
								<a class="carousel-control-next" href="#" role="button" data-slide="next">
									<span class="carousel-control-next-icon" aria-hidden="true"></span>
									<span class="sr-only">Next</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>)
	}
} 
export default Retail