import React, { Component } from "react";
import { withRouter } from 'react-router';
import Header from '../Header'
import Footer from "../Footer";
import {URL_IMG} from "../../constants";
import axios from 'axios';
    
class About extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
			offers:[{},{},{},{},{},{}],
		}
	}
  componentDidMount(){
		// axios
		// .get("https://api.qamaralmadinauae.com/api/get-site-data")
		// .then((response) => {
		// 	this.setState({offers:response.data.offers_page})
		// })
  }
	render(){
		const {offers} = this.state;
		return(
			<div id="home-component">
				<Header/>
				<div class="agent content-area-2" id="product">
					{window.innerWidth>600?<>
					<img src="assets/img/anim/textanimation_icon5.png" className="moving-imgs img1" />
					<img src="assets/img/anim/textanimation_icon2.png" className="moving-imgs img2" />
					<img src="assets/img/anim/textanimation_icon3.png" className="moving-imgs img3" />
					<img src="assets/img/anim/textanimation_icon5.png" className="moving-imgs img4" />
					<img src="assets/img/anim/textanimation_icon1.png" className="moving-imgs img5" />
					<img src="assets/img/anim/textanimation_icon4.png" className="moving-imgs img6" />
					<img src="assets/img/anim/textanimation_icon3.png" className="moving-imgs img7" />
					<img src="assets/img/anim/textanimation_icon5.png" className="moving-imgs img8" />
					<img src="assets/img/anim/textanimation_icon4.png" className="moving-imgs img9" />
					<img src="assets/img/anim/textanimation_icon2.png" className="moving-imgs img10" />
					<img src="assets/img/anim/textanimation_icon1.png" className="moving-imgs img11" />
					<img src="assets/img/anim/textanimation_icon5.png" className="moving-imgs img12" />
					<img src="assets/img/anim/textanimation_icon3.png" className="moving-imgs img13" />
					<img src="assets/img/anim/textanimation_icon4.png" className="moving-imgs img14" />
					<img src="assets/img/anim/textanimation_icon2.png" className="moving-imgs img15" />
					</>:""}
					<div class="container">
						<div class="main-title">
							<h1><span>Our</span> Offers</h1>
							<p style={{fontWeight:500}}>Check our exciting deals and offers.</p>
						</div>
						{offers.map((product,index)=>{
							if(index%4==0)
								return(
									<div class="row">
										{offers.slice(index,index+4).map(item=>{
											return(
												<div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 product-div">
													<div class="agent-2">
														<div class="agent-photo">
															<a href="#">
																<img src={URL_IMG + item.image} alt="avatar" class="img-fluid"/>
															</a>
														</div>
													</div>
												</div>
											) 
										})}
									</div>
								)
						})}
					</div>
				</div >
			<Footer/>
		</div>)
	}
}
export default withRouter(About)
