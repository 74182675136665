import React, { Component } from "react";
// import {URL_IMG} from "../../constants";

class Offers extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
		}
	}
	render(){
		const {homeOffers} = this.props;
		return(
			<div class="portfolio-area content-area-2 offers-area" id="offers">
				<div class="container">
					<div class="main-title">
						<h1><span>Our</span> Offers</h1>
						<div style={{display: 'flex',textAlign: 'center',margin: 'auto',justifyContent: 'flex-start'}}>
							<lord-icon
									src="https://cdn.lordicon.com/lupuorrc.json"
									trigger="loop"
									colors="primary:#121331,secondary:#08a88a"
									style={{width:"50px",height:"50px"}}>
							</lord-icon>
							<a href="/our-offers" style={{color:"white",fontWeight:"500",borderRadius:"10px",lineHeight:'50px',
								border:"1px solid gold", padding:"0px 10px",background: '#a4c632'}}>
								View exciting offers</a>
							<lord-icon
									src="https://cdn.lordicon.com/lupuorrc.json"
									trigger="loop"
									colors="primary:#121331,secondary:#08a88a"
									style={{width:"50px",height:"50px"}}>
							</lord-icon>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-12">
							{/* {window.innerWidth>600?<>
							<img src="assets/img/anim/textanimation_icon5.png" className="moving-imgs img1" />
							<img src="assets/img/anim/textanimation_icon2.png" className="moving-imgs img2" />
							<img src="assets/img/anim/textanimation_icon3.png" className="moving-imgs img3" />
							<img src="assets/img/anim/textanimation_icon5.png" className="moving-imgs img4" />
							<img src="assets/img/anim/textanimation_icon1.png" className="moving-imgs img5" />
							<img src="assets/img/anim/textanimation_icon4.png" className="moving-imgs img6" />
							<img src="assets/img/anim/textanimation_icon3.png" className="moving-imgs img7" />
							<img src="assets/img/anim/textanimation_icon5.png" className="moving-imgs img8" />
							<img src="assets/img/anim/textanimation_icon4.png" className="moving-imgs img9" />
							<img src="assets/img/anim/textanimation_icon4.png" className="moving-imgs img10" />
							<img src="assets/img/anim/textanimation_icon4.png" className="moving-imgs img11" />
							<img src="assets/img/anim/textanimation_icon4.png" className="moving-imgs img12" />
							<img src="assets/img/anim/textanimation_icon4.png" className="moving-imgs img13" />
							<img src="assets/img/anim/textanimation_icon4.png" className="moving-imgs img14" />
							<img src="assets/img/anim/textanimation_icon4.png" className="moving-imgs img15" />
							</>:""} */}
						  <div id="myOffersCarousel" class="carousel Project-details-sliders slide mb-50" data-ride="carousel">
								<div class="carousel-inner">
										{homeOffers.map(retail=>{
											return(
												<div class={"carousel-item"+(retail.seq_no==0?" active":"")}>
													<img class="img-fluid" src={retail.image} alt="Second slide"/>
												</div>)
										})}
								</div>
								<div class="carousel-indicators">
									<button type="button" data-bs-target="#myOffersCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
									<button type="button" data-bs-target="#myOffersCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
									<button type="button" data-bs-target="#myOffersCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
								</div>
							</div>
						</div>
					</div>
						<div style={{display: 'flex',textAlign: 'center',margin: 'auto',justifyContent: 'flex-end'}}>
							<lord-icon
									src="https://cdn.lordicon.com/lupuorrc.json"
									trigger="loop"
									colors="primary:#121331,secondary:#08a88a"
									style={{width:"50px",height:"50px"}}>
							</lord-icon>
							<a href="/our-offers" style={{color:"white",fontWeight:"500",borderRadius:"10px",lineHeight:'50px',
								border:"1px solid gold", padding:"0px 10px",background: '#a4c632'}}>
								View exciting offers</a>
							<lord-icon
									src="https://cdn.lordicon.com/lupuorrc.json"
									trigger="loop"
									colors="primary:#121331,secondary:#08a88a"
									style={{width:"50px",height:"50px"}}>
							</lord-icon>
						</div>
				</div>
			</div>)
	}
} 
export default Offers