import React, { Component } from "react";
// import {URL_IMG} from "../../constants";

class Products extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
		}
	}
	componentDidMount(){
		window.scrollTo(0, 0);
	}
	render(){
		const	{products} = this.props;
		return(
			<div class="agent content-area-2" id="product">
				<div class="container">
					<div class="main-title">
						<h1><span>Our</span> Products</h1>
						<p>We cover all your needs from multiple categories.</p>
					</div>
					{products.map((product,index)=>{
						if(index%4==0)
							return(
								<div class="row">
									{products.slice(index,index+4).map(item=>{
										return(
											<div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 product-div">
												<div class="agent-2">
													<div class="agent-photo">
														<a href="#">
															<img src={item.image} alt="avatar" class="img-fluid"/>
														</a>
													</div>
													<div class="agent-details">
														<h5><a href="#">{item.title}</a></h5>
													</div>
												</div>
											</div>
										) 
									})}
								</div>
							)
					})}
				</div>
			</div >)
	}
} 
export default Products