import React, { Component } from "react";
import { withRouter } from 'react-router';
import Header from '../Header'
import Footer from "../Footer";
import axios from 'axios';
    
class About extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
			homeMedia:[{image:'assets/img/product-1.jpg'},{image:'assets/img/product-2.jpg'},{image:'assets/img/product-3.jpg'},{image:'assets/img/product-4.jpg'},{image:'assets/img/product-5.jpg'},{image:'assets/img/product-6.jpg'}],
		}
	}
  componentDidMount(){
		// axios
		// .get("https://api.qamaralmadinauae.com/api/get-site-data")
		// .then((response) => {
		// 	this.setState({homeMedia:response.data.home_media})
		// })
  }
	render(){
		const {homeMedia} = this.state;
		return(
			<div id="home-component">
				<Header/>
<div class="portfolio-area content-area-2">
    <div class="container">
        <div class="main-title">
            <h1><span>We are on</span> Media</h1>
            
        </div>
        <div class="row filter-portfolio">
            <div class="cars">
                {homeMedia.map(media=>{
                    return(
											
											<div class="col-lg-4 col-md-4 col-sm-6 filtr-item" data-category="3">
											<div class="property-box mb-30">
													<div class="property-thumbnail">
															<a href="portfolio-details.html" class="property-img">
																<img src={media.image} alt="portfolio" class="img-fluid"/>
															</a>
															<div class="property-overlay">
																	<a href="portfolio-details.html" class="overlay-link">
																			<i class="fa fa-link"></i>
																	</a>
																	<div class="property-magnify-gallery">
																			<a href={homeMedia[0].image} class="overlay-link">
																					<i class="fa fa-expand"></i>
																			</a>
																			{homeMedia.map(item=>{
																				if(item.seq_no>0)
																				return(
																					<a href={item.image} class="hidden"></a>
																				)
																			})}
																	</div>
															</div>
													</div>
											</div>
									</div>
                        // <div class="col-lg-4 col-md-4 col-sm-6 filtr-item" data-category="3">
                        //     <div class="property-box mb-30">
                        //         <div class="property-thumbnail">
                        //             <a href="portfolio-details.html" class="property-img">
                        //             </a>
                        //             <div class="property-overlay">
                        //                 <div class="property-magnify-gallery">
                        //                     <a href={URL_IMG + media.image} class="overlay-link">
                        //                         <i class="fa fa-expand"></i>
                        //                     </a>
                        //                     <a href={URL_IMG + "750x540.png"} class="hidden"></a>
                        //                     <a href={URL_IMG + "750x540.png"} class="hidden"></a>
                        //                 </div>
                        //             </div>
                        //         </div>
                        //     </div>
                        // </div>
                    )
                })}
            </div>
        </div>
    </div>
</div>
			<Footer/>
		</div>)
	}
}
export default withRouter(About)
