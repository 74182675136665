import React, { Component } from "react";
import { withRouter } from 'react-router';
import Header from '../Header'
import Footer from "../Footer";
    
class About extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1
		}
	}
	render(){
		return(
			<div id="home-component">
				<Header/>
<div class="contact-1 content-area-7">
    <div class="container">
        <div class="main-title">
            <h1><span>Contact</span> Us</h1>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p> */}
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <form action="#" method="GET" enctype="multipart/form-data">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="form-group name">
                                <input type="text" name="name" class="form-control" placeholder="Name"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="form-group email">
                                <input type="email" name="email" class="form-control" placeholder="Email"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="form-group subject">
                                <input type="text" name="subject" class="form-control" placeholder="Subject"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="form-group number">
                                <input type="text" name="phone" class="form-control" placeholder="Number"/>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-group message">
                                <textarea class="form-control" name="message" placeholder="Write message"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div class="send-btn">
                                <button type="submit" class="btn btn-color btn-md btn-message">Send Message</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <h3>Contact Info</h3>
                    <div class="media">
                        <i class="fa fa-map-marker"></i>
                        <div class="media-body">
                            <h5 class="mt-0">Office Address</h5>
                            <p>Yasmeen Street</p>
                            <p>Opposite Rahmaniya</p>
                            <p>Ajman, United Arab Emirates</p>
                        </div>
                    </div>
                    <div class="media">
                        <i class="fa fa-phone"></i>
                        <div class="media-body">
                            <h5 class="mt-0">Phone Number</h5>
                            <p>050 6209570</p>
                            <p>06 522 4825</p>
                        </div>
                    </div>
                    <div class="media">
                        <i class="fa fa-envelope"></i>
                        <div class="media-body">
                            <h5 class="mt-0">Email Address</h5>
                            <p><a href="#">carryfreshmarket@gmail.com</a></p>
                        </div>
                    </div>
                    {/* <div class="media mrg-btn-0">
                        <i class="fa fa-fax"></i>
                        <div class="media-body">
                            <h5 class="mt-0">Fax Number</h5>
                            <p>Office<a href="tel:0477-0477-8556-552">: +0477 8556 552</a> </p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section">
    <div class="map">
        <div id="contactMap" class="contact-map"></div>
    </div>
</div>
			<Footer/>
		</div>)
	}
}
export default withRouter(About)
