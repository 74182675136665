import React, { Component } from "react";
import { withRouter } from 'react-router';
    
class Header extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1
		}
	}
	render(){
		const tabs = [{label:"Home",link:"/"},{label:"About us",link:"about"},
			// {label:"Stores",link:"stores"},
			// {label:"Events",link:"events"},
			// {label:"Projects",link:"projects"},
			// {label:"Offers",link:"our-offers"},
			// {label:"Media",link:"media"},
			{label:"Contact",link:"contact"}]
		return(
			<div id="home-component">
				<header class="main-header do-sticky" id="main-header-2">
					<div class="container">
						<div class="row">
							<div class="col-12">
								<nav class="navbar navbar-expand-lg navbar-light rounded">
									<a class="navbar-brand logo" href="/">
										<img src="assets/img/logos/logo.png" alt="logo"/>
									</a>  
									<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
										<span class="fa fa-bars"></span>
									</button>	                  
									<div class="navbar-collapse collapse" id="navbar">
										<ul class="navbar-nav ml-auto">
											{tabs.map(tab=>{
												return(
													<li class={"nav-item" +(window.location.pathname=="/"+tab.link?" active":"")}>
														<a class="nav-link" href={tab.link}>
															{tab.label}
														</a>
													</li>
												)
											})}
										</ul>
									</div>
                </nav>
							</div>
						</div>
					</div>
				</header>
</div>)}
}
export default withRouter(Header)
