import React, { Component } from "react";
// import {URL_IMG} from "../../constants";

class Managment extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
		}
	}
	componentDidMount(){
		window.scrollTo(0, 0);
	}
	render(){
		const {homeAbout} = this.props;
		return(
			<div class="managment-area-2 content-area-10 about" id="about">
				<div class="container">
					<div class="main-title">
						<h1><span>About</span> Us</h1>
						{/* <p>{homeAbout[0].title}</p> */}
						<p>Since the establishment, we offer the best shopping experience for customers. Fueled with a corporate vision and dedicated professionals, we serve excellence in every single venture.</p>
					</div>
					<div class="row">
						<div class="col-lg-5 col-md-5">
							<div class="managment-info">
								<h1><span>Why</span> Choose Us?</h1>
								<div class="managment-border-"></div>
								{/* <p>{homeAbout[0].description}</p> */}
								<p>{"We’re committed to deliver the best services focusing on consumers, what makes us different in staying rooted with ethical business practices,"}</p>
								<ul>
									<li style={{textAlign: 'left'}}><i class="flaticon-up-arrow"></i>Unmatched retail experience</li>
									<li style={{textAlign: 'left'}}><i class="flaticon-up-arrow"></i>Unique customer experience with exceptional quality and service</li>
									<li style={{textAlign: 'left'}}><i class="flaticon-up-arrow"></i>True to core values and deliver excellence</li>
									<li style={{textAlign: 'left'}}><i class="flaticon-up-arrow"></i>Continue to grow current sectors</li>
									<li style={{textAlign: 'left'}}><i class="flaticon-up-arrow"></i>Sustainable in the ever changing market</li>
								</ul>
								<p>We are unique by emphasizing on competitive prices and so, our customers relish with a convenient and quick shopping experience!</p>
								<a href="/about" class="btn btn-lg btn-theme">Read More</a>
							</div>
						</div>
						<div class="col-lg-6 col-md-7 offset-lg-1">
							<div class="managment-slider">
								<div id="carouselExampleIndicators-managment" class="carousel slide" data-ride="carousel">
									<ol class="carousel-indicators">
										<li data-target="#carouselExampleIndicators-managment" data-slide-to="0" class="active"></li>
										<li data-target="#carouselExampleIndicators-managment" data-slide-to="1"></li>
										<li data-target="#carouselExampleIndicators-managment" data-slide-to="2"></li>
									</ol>
									<div class="carousel-inner">
										{homeAbout.map(about=>{
											if(about.seq_no>0)
												return(
													<div class={"carousel-item"+(about.seq_no==1?" active":"")}>
														<img class="d-block w-100 img-fluid" src={about.image} alt="Second slide"/>
													</div>)
										})}
									</div>
									<a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
										<span class="carousel-control-prev-icon" aria-hidden="true"></span>
										<span class="sr-only">Previous</span>
									</a>
									<a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
										<span class="carousel-control-next-icon" aria-hidden="true"></span>
										<span class="sr-only">Next</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
		</div>)
	}
} 
export default Managment