import React, { Component } from "react";
// import {URL_IMG} from "../../constants";

class Portfolio extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
		}
	}
	componentDidMount(){
		window.scrollTo(0, 0);
	}
	render(){
		const {homeMedia} = this.props;
		return(
			<div class="portfolio-area content-area-2" id="media">
				<div class="container">
					<div class="main-title">
						<h1><span>Our</span> Products</h1>
					</div>
					<div class="row filter-portfolio">
						<div class="cars">
							{homeMedia.map(media=>{
								return(
									<div class="col-lg-4 col-md-4 col-sm-6 filtr-item" data-category="3">
										<div class="property-box mb-30">
											<div class="property-thumbnail">
												<a href="portfolio-details.html" class="property-img">
													<img src={media.image} alt="portfolio" class="img-fluid"/>
												</a>
												<div class="property-overlay">
													<div class="property-magnify-gallery">
														<a href={media.image} class="overlay-link">
															<i class="fa fa-expand"></i>
														</a>
														{/* <a href={URL_IMG + "750x540.png"} class="hidden"></a>
														<a href={URL_IMG + "750x540.png"} class="hidden"></a> */}
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							})}
							{/* <div class="col-lg-4 col-md-4 col-sm-6 filtr-item" data-category="3, 2, 1">
								<div class="property-box mb-30">
									<div class="property-thumbnail">
										<a href="portfolio-details.html" class="property-img">
											<img src={URL_IMG + "media-2.jpg"} alt="portfolio" class="img-fluid"/>
										</a>
										<div class="property-overlay">
											<div class="property-magnify-gallery">
												<a href={URL_IMG + "media-full-1.jpg"} class="overlay-link">
													<i class="fa fa-expand"></i>
												</a>
												<a href={URL_IMG + "750x540.png"} class="hidden"></a>
												<a href={URL_IMG + "750x540.png"} class="hidden"></a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-6 filtr-item" data-category="1, 2">
								<div class="property-box mb-30">
									<div class="property-thumbnail">
										<a href="portfolio-details.html" class="property-img">
											<img src={URL_IMG + "media-3.jpg"} alt="portfolio" class="img-fluid"/>
										</a>
										<div class="property-overlay">
											<div class="property-magnify-gallery">
												<a href={URL_IMG + "media-full-1.jpg"} class="overlay-link">
													<i class="fa fa-expand"></i>
												</a>
												<a href={URL_IMG + "750x540.png"} class="hidden"></a>
												<a href={URL_IMG + "750x540.png"} class="hidden"></a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-6 filtr-item" data-category="3, 2">
								<div class="property-box mb-30">
									<div class="property-thumbnail">
										<a href="portfolio-details.html" class="property-img">
											<img src={URL_IMG + "media-1.jpg"} alt="portfolio" class="img-fluid"/>
										</a>
										<div class="property-overlay">
											<div class="property-magnify-gallery">
												<a href={URL_IMG + "media-full-1.jpg"} class="overlay-link">
													<i class="fa fa-expand"></i>
												</a>
												<a href={URL_IMG + "750x540.png"} class="hidden"></a>
												<a href={URL_IMG + "750x540.png"} class="hidden"></a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-6 filtr-item" data-category="2, 1">
								<div class="property-box mb-30">
									<div class="property-thumbnail">
										<a href="portfolio-details.html" class="property-img">
											<img src={URL_IMG + "media-3.jpg"} alt="portfolio" class="img-fluid"/>
										</a>
										<div class="property-overlay">
											<div class="property-magnify-gallery">
												<a href={URL_IMG + "media-full-1.jpg"} class="overlay-link">
													<i class="fa fa-expand"></i>
												</a>
												<a href={URL_IMG + "750x540.png"} class="hidden"></a>
												<a href={URL_IMG + "750x540.png"} class="hidden"></a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4 col-sm-6 filtr-item" data-category="3, 1, 2">
								<div class="property-box mb-30">
									<div class="property-thumbnail">
										<a href="portfolio-details.html" class="property-img">
											<img src={URL_IMG + "media-2.jpg"} alt="portfolio" class="img-fluid"/>
										</a>
										<div class="property-overlay">
											<div class="property-magnify-gallery">
												<a href={URL_IMG + "media-full-1.jpg"} class="overlay-link">
														<i class="fa fa-expand"></i>
												</a>
												<a href={URL_IMG + "750x540.png"} class="hidden"></a>
												<a href={URL_IMG + "750x540.png"} class="hidden"></a>
											</div>
										</div>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>)
	}
} 
export default Portfolio