import React, { Component } from "react";
import Banner from "./Banner";
import Managment from "./Managment";
import Products from "./Products";
import Offers from "./Offers";
import Projects from "./Projects";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import Footer from "../Footer";
import Header from "../Header";
import Retail from "./Retail"
import Wholesale from "./Wholesale"
import { withRouter } from 'react-router';
import axios from 'axios';
    
class Home extends Component{
	constructor(props){
		super();
		this.state={
			showSearchProductsPageKey:1,
			currentDiv:0,
			products:[],
			homeAbout:[{},{},{},{}],
			homeWholesales:[{},{},{},{}],
			homeRetails:[{},{},{},{}],
			homeOffers:[{},{},{}],
			homeProjects:[{},{},{}],
			homeBannersMob:[{},{},{}],
			homeBanners:[{image:"assets/img/banner-1.jpg"},{image:"assets/img/banner-2.jpg"}],
			homeMedia:[{image:'assets/img/product-1.jpg'},{image:'assets/img/product-2.jpg'},{image:'assets/img/product-3.jpg'},{image:'assets/img/product-4.jpg'},{image:'assets/img/product-5.jpg'},{image:'assets/img/product-6.jpg'}],
		}
	}
  componentDidMount(){
		// axios
		// .get("https://api.qamaralmadinauae.com/api/get-site-data")
		// .then((response) => {
		// 	this.setState({products:response.data.products})
		// 	this.setState({homeAbout:response.data.home_about})
		// 	this.setState({homeWholesales:response.data.home_wholesales})
		// 	this.setState({homeRetails:response.data.home_retails})
		// 	this.setState({homeOffers:response.data.home_offers})
		// 	this.setState({homeProjects:response.data.home_projects})
		// 	this.setState({homeBanners:response.data.home_banners})
		// 	this.setState({homeBannersMob:response.data.home_banners_mob})
		// 	this.setState({homeMedia:response.data.home_media})
		// })
  }
	render(){
		return(
			<div id="home-component">
				<Header/>
				{/* <div ref={this.myRef[1]}> */}
					<Banner homeBanners = {this.state.homeBanners} homeBannersMob = {this.state.homeBannersMob}/>
				{/* </div>
				<div ref={this.myRef[2]}> */}
				<Managment homeAbout={this.state.homeAbout}/>
				{/* </div>
				<div ref={this.myRef[3]}> */}
				{/* <Wholesale homeWholesales={this.state.homeWholesales}/> */}
				{/* </div>
				<div ref={this.myRef[4]}> */}
				{/* <Retail homeRetails={this.state.homeRetails}/> */}
				{/* </div>
				<div ref={this.myRef[5]}> */}
				{/* <Products products={this.state.products}/> */}
				{/* </div>
				<div ref={this.myRef[6]}> */}
				{/* <Offers homeOffers={this.state.homeOffers}/> */}
				{/* </div>
				<div ref={this.myRef[7]}> */}
				{/* <Projects homeProjects={this.state.homeProjects}/> */}
				{/* </div>
				<div ref={this.myRef[8]}> */}
				<Portfolio homeMedia={this.state.homeMedia}/>
				{/* </div>
				<div ref={this.myRef[9]}> */}
				<Contact/>
				{/* </div>
				<div ref={this.myRef[10]}> */}
				<Footer/>
				{/* </div> */}
			</div>)}
}
export default withRouter(Home)
